<template>
  <div class="start-assessment container">
    <div class="logo"><router-link to="/"><img src="@/assets/images/storesafe-logo.png" width="100%" /></router-link></div>
    <h1>Start Assessment</h1>
    <h4>Let's give your new assessment a name.</h4>
    <div class="form-group">
      <form @submit.prevent="startAssessment">
        <br />
        <input v-model="title" class="form-control" placeholder="Coffee Shop on Main" required>
        <br />
        <button type="submit" class="btn-register">Start Assessment</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { auth } from '@/api';

export default {
  name: 'StartAssessment',
  setup() {
    const store = useStore();
    const router = useRouter();
    const title = ref('');

    const startAssessment = async () => {
      try {
        const authResponse = await auth.checkAuth();
        if (!authResponse.isAuthenticated) {
          router.push('/login');
          return;
        }
    
        console.log('Creating new assessment:', title.value);
        const response = await axios.post('/api/assessment', {
          title: title.value,
          userId: authResponse.user.id,
          answers: {},
          currentQuestionIndex: 0,
          completed: false
        }, {
          withCredentials: true,
          baseURL: 'https://storesafe.fyi'
        });
    
        console.log('New assessment created:', response.data);
        await router.push({
          name: 'Assessment',
          params: { id: response.data.id.toString() }
        });
      } catch (error) {
        console.error('Error starting new assessment:', error);
      }
    };

    return {
      title,
      startAssessment
    };
  }
};
</script>