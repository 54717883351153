// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import html2pdf from 'html2pdf.js';
import WebFont from 'webfontloader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles.css';
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://storesafe.fyi';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'https://storesafe.fyi',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

const checkLocalStorageAccess = () => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

const storage = checkLocalStorageAccess() ? localStorage : {
  _data: {},
  setItem(id, val) { this._data[id] = val; },
  getItem(id) { return this._data[id]; },
  removeItem(id) { delete this._data[id]; }
};

export default apiClient;

// WebFont.load({
//   google: {
//     families: ['Rajdhani:300,400,500,600,700']
//   }
// });

const app = createApp(App)

app.config.globalProperties.$html2pdf = html2pdf;

app.use(router)
app.use(store)

// Check if we're in an iframe
if (window.self !== window.top) {
  // We're in an iframe, use the stored route
  const lastRoute = localStorage.getItem('lastRoute')
  if (lastRoute) {
    router.push(lastRoute)
  }
}

app.mount('#app')
