<template>
  <div class="dashboard">
    <div class="logo"><router-link to="/"><img src="@/assets/images/storesafe-logo.png" width="100%" /></router-link></div>
    <div class="dash-message">
      <h3>Our StoreSafe Assessment is a detailed intake of how well your team is staying up to FDA Food Code.</h3>
      Fill out the 50-question assessment to see how your operation scores on Food Safety and see how your Food Safety score impacts your Cost Savings, Labor Efficiency, Space Optimization, and Sustainability initiatives. Once you complete your assessment, you’ll receive an automated report on Cambro product solutions based on your answers.
    </div>
    <div class="dash-header">
      <button @click="startNewAssessment" class="btn-start">New Assessment</button>
      <h1>Your Assessments</h1>
    </div>
    <div class="dash-assess">
      <div v-for="assessment in assessments" :key="assessment.id" class="dash-box">
		  <div v-if="editingId === assessment.id">
		    <input 
              v-model="editTitle" 
              @keyup.enter="updateAssessmentTitle(assessment)"
              @keyup.esc="cancelEdit"
              class="form-control"
              placeholder="Enter new title"
              autofocus
            >
		    <button @click="updateAssessmentTitle(assessment)" class="save-btn">Save</button>
            <button @click="cancelEdit" class="cancel-btn">Cancel</button>
		  </div>
		  <h2>{{ assessment.title }}</h2>
		  <div class="assessment-meta">
		    <div class="assessment-date"><strong>Started:</strong> {{ formatDate(assessment.createdAt) }}</div>
		    <strong>Progress:</strong>
		    <div class="progress-bar">
			  <div class="progress-fill" :style="{ width: `${calculateProgress(assessment)}%` }"></div>
			</div>
		  </div>
		  <button @click="continueAssessment(assessment.id)" class="dash-continue">Continue</button>
		  <button @click="viewResults(assessment)" class="dash-results">View Results</button>
		  <button @click="startEdit(assessment)" class="dash-edit">Edit Title</button>
		  <button @click="deleteAssessment(assessment)" class="dash-delete">Delete</button>
		  <div class="cleaner"></div>
		</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { setRouter, auth } from '@/api';

export default {
  name: 'Dashboard',
  setup() {
    const store = useStore();
    const router = useRouter();
    setRouter(router);

    const assessments = ref([]);
    const editingId = ref(null);
    const editTitle = ref('');

    const fetchAssessments = async () => {
      try {
        const authResponse = await auth.checkAuth();
        if (!authResponse.isAuthenticated) {
          console.log('Not authenticated, redirecting to login');
          router.push('/login');
          return;
        }
    
        const userId = authResponse.user.id;
        console.log('Fetching assessments for user:', userId);
        
        // First get questions to know total count
        const [assessmentsResponse, questionsResponse] = await Promise.all([
          axios.get(`/api/assessment/user/${userId}`, {
            withCredentials: true,
            baseURL: 'https://storesafe.fyi'
          }),
          axios.get('/api/questions', {
            withCredentials: true,
            baseURL: 'https://storesafe.fyi'
          })
        ]);
        
        const totalQuestions = questionsResponse.data.length;
        console.log('Total questions available:', totalQuestions);
        
        assessments.value = assessmentsResponse.data.map(assessment => ({
          ...assessment,
          questions: assessment.questions || [],
          totalQuestions // Add total questions count
        }));
        
        console.log('Assessments loaded:', assessments.value);
      } catch (error) {
        console.error('Error fetching assessments:', error);
      }
    };

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    const calculateProgress = (assessment) => {
      console.log('Calculating progress for assessment:', {
        id: assessment.id,
        title: assessment.title,
        answersType: typeof assessment.answers
      });
    
      if (!assessment?.answers) {
        console.log('No answers found for assessment:', assessment.id);
        return 0;
      }
    
      // Convert Proxy to regular object and get real keys
      const answersObj = Object.fromEntries(
        Object.entries(assessment.answers).filter(([key]) => !['assessmentId', 'title'].includes(key))
      );
      
      const answeredQuestions = Object.keys(answersObj).length;
      const totalQuestions = 50; // Matches your actual total questions
    
      const progress = Math.round((answeredQuestions / totalQuestions) * 100);
      
      console.log('Progress calculated:', {
        answeredQuestions,
        totalQuestions,
        progress
      });
    
      return progress;
    };

    const startEdit = (assessment) => {
      editingId.value = assessment.id;
      editTitle.value = assessment.title || ''; // Initialize with current title
      console.log('Starting edit for:', {
        id: assessment.id,
        title: assessment.title,
        editTitle: editTitle.value
      });
    };

    const cancelEdit = () => {
      editingId.value = null;
      editTitle.value = '';
    };

    const updateAssessmentTitle = async (assessment) => {
      try {
        console.log('Updating title:', {
          assessmentId: assessment.id,
          newTitle: editTitle.value
        });
    
        const response = await axios.put(`/api/assessment/${assessment.id}`, {
          title: editTitle.value,
          answers: assessment.answers, // Send existing answers
          currentQuestionIndex: assessment.currentQuestionIndex,
          userId: assessment.userId,
          completed: assessment.completed
        }, {
          withCredentials: true,
          baseURL: 'https://storesafe.fyi'
        });
        
        // Update the local assessment title
        const updatedAssessment = assessments.value.find(a => a.id === assessment.id);
        if (updatedAssessment) {
          updatedAssessment.title = editTitle.value;
        }
        
        // Reset edit state
        editingId.value = null;
        editTitle.value = '';
    
        console.log('Title updated successfully:', response.data);
      } catch (error) {
        console.error('Error updating assessment title:', error);
      }
    };

    const deleteAssessment = async (assessment) => {
      if (confirm('Are you sure you want to delete this assessment?')) {
        try {
          await axios.delete(`/api/assessment/${assessment.id}`, {
            withCredentials: true,
            baseURL: 'https://storesafe.fyi'
        });
          assessments.value = assessments.value.filter(a => a.id !== assessment.id);
        } catch (error) {
          console.error('Error deleting assessment:', error);
        }
      }
    };

    const continueAssessment = (id) => {
      console.log('Navigating to assessment:', id);
      router.push({
        name: 'Assessment',
        params: { id: String(id) }
      }).catch(err => console.error('Navigation error:', err));
    };

    const viewResults = (assessment) => {
      console.log('Navigating to results:', assessment.id);
      router.push({
        name: 'Results',
        params: { id: String(assessment.id) }
      }).catch(err => console.error('Navigation error:', err));
    };

    const startNewAssessment = () => {
      console.log('Starting new assessment');
      router.push({ name: 'StartAssessment' })
        .catch(err => console.error('Navigation error:', err));
    };

    onMounted(async () => {
      console.log('Component mounted');
      try {
        const authStatus = await auth.checkAuth();
        console.log('Auth status in component:', authStatus);
        
        if (!authStatus.isAuthenticated) {
          console.log('Not authenticated in component');
          router.push('/login');
          return;
        }
        
        await fetchAssessments();
      } catch (error) {
        console.error('Error in mounted:', error);
        router.push('/login');
      }
    });

    return {
      assessments,
      editingId,
      editTitle,
      fetchAssessments,
      continueAssessment,
      viewResults,
      startNewAssessment,
      startEdit,
      cancelEdit,
      updateAssessmentTitle,
      deleteAssessment,
      formatDate,
      calculateProgress
    };
  }
};
</script>