import { createStore } from 'vuex';
import axios from 'axios';

const store = createStore({
  state: {
    auth: {
      user: null
    }
  },
  getters: {
    isAuthenticated: state => !!state.auth.user,
    getUser: state => state.auth.user,
    isAdmin: state => state.auth.user?.isAdmin || false
  },
  mutations: {
    setUser(state, user) {
      state.auth.user = user;
    },
    clearUser(state) {
      state.auth.user = null;
    }
  },
  actions: {
    async checkAuth({ commit }) {
      try {
        const response = await axios.get('https://storesafe.fyi/api/auth/check-auth', {
          withCredentials: true
        });
        if (response.data.isAuthenticated) {
          commit('setUser', response.data.user);
        }
      } catch (error) {
        console.error('Auth check error:', error);
        commit('clearUser');
      }
    }
  }
});

export default store;