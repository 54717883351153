<template>
  <div class="admin-dashboard">
    <h1>Admin Dashboard</h1>
    <div v-if="!isAdmin">You do not have permission to view this page.</div>
    <div v-else-if="loading">Loading...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="sorting-controls">
        <label for="sort-select">Sort by: </label>
        <select id="sort-select" v-model="sortBy">
          <option value="alphabetical">Alphabetical</option>
          <option value="newest">Newest Account</option>
          <option value="recent">Most Recent Assessment</option>
        </select>
      </div>
      <div class="admin-dash-assessments">
        <div v-for="user in sortedUsers" :key="user.id" class="user-card">
          <h3 class="admin-name">{{ user.name }}</h3>
          <div class="admin-email"><a :href="'mailto:' + user.email">{{ user.email }}</a></div>
          <h5 class="admin-company">Company: <div class="admin-text">{{ user.company }}</div></h5>
          <h5 class="admin-industry">Industry:  <div class="admin-text">{{ user.industry }}</div></h5>
          <div class="admin-assessments">Assessments:</div>
          <ul class="admin-list">
            <li v-for="assessment in user.assessments" :key="assessment.id">
              {{ assessment.title }} <span class="admin-date">Started: {{ new Date(assessment.createdAt).toLocaleDateString() }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getValidToken } from '@/utils/authUtils';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AdminDashboard',
  data() {
    return {
      users: [],
      loading: true,
      error: null,
      sortBy: 'alphabetical'
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    isAdmin() {
      return this.user && this.user.isAdmin;
    },
    sortedUsers() {
      if (this.sortBy === 'alphabetical') {
        return [...this.users].sort((a, b) => (a.name || '').localeCompare(b.name || ''));
      } else if (this.sortBy === 'newest') {
        return [...this.users].sort((a, b) => new Date(b.createdAt || 0) - new Date(a.createdAt || 0));
      } else if (this.sortBy === 'recent') {
        return [...this.users].sort((a, b) => {
          const latestA = a.assessments && a.assessments.length ? Math.max(...a.assessments.map(assessment => new Date(assessment.createdAt || 0))) : 0;
          const latestB = b.assessments && b.assessments.length ? Math.max(...b.assessments.map(assessment => new Date(assessment.createdAt || 0))) : 0;
          return latestB - latestA;
        });
      }
      return this.users;
    }
  },
  methods: {
    ...mapActions(['logout']),
    async fetchAdminData() {
      try {
        const token = await getValidToken();
        console.log('Token:', token);
        
        if (!token) {
          this.error = 'No valid token found. Please log in again.';
          return;
        }

        const response = await axios({
          method: 'get',
          url: 'https://storesafe.fyi/admin/users-with-assessments',
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });
        
        // Add debugging logs
        console.log('Response status:', response.status);
        console.log('Response headers:', response.headers);
        console.log('Response data:', response.data);
        
        if (response.data && Array.isArray(response.data)) {
          this.users = response.data;
          console.log('Users array:', this.users);
        } else {
          console.error('Unexpected response format:', response.data);
          this.error = 'Invalid data format received from server';
        }
      } catch (err) {
        console.error('Full error object:', err);
        console.error('Response data:', err.response?.data);
        console.error('Response status:', err.response?.status);
        console.error('Response headers:', err.response?.headers);
        
        this.error = err.response?.data?.message || 'Error fetching admin data';
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.fetchAdminData();
  }
};
</script>

<style scoped>
.sorting-controls {
  margin-bottom: 20px;
}
select {
  padding: 5px;
  font-size: 16px;
}
</style>